$(function () {
    var currentModal;

    $('.projectLearnMore').on('click', (e) => {
        currentModal = $('#' + e.target.id + 'Modal')

        currentModal.modal('show');
        e.preventDefault();
    });

    $('.projectModalClose').on('click', (e) => {
        currentModal.modal('hide');
    })

    $('#projects_link').on('click', (e) => {
        $('#projects-tab').trigger('click');

        e.preventDefault();
    })
});