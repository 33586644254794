$(function () {
    $(".bullet").on("click", function () {
        $('.bullet').removeClass('active');
        $(this).addClass('active');

        var target = $(this).find("span").data('target');

        $('.event').hide(); // you can add ms in here to active effects
        $(target).show();

        $('#timeline_click_to_show').hide();
    });
});